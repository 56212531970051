html,
body {
    cursor: url(./static/boombox-cursor.png), auto;
    color: #fff;
    background-image: url(./static/seals1.gif);
}

#body1 {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    background-image: url(/static/patrick.gif);
    background-repeat: no-repeat;
    background-size: cover;
    animation-name: stretch;
    animation-duration: 10s;
    animation-timing: cubic-bezier(1, 1, .1, 0);
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes stretch {
    0% {
        width: 10px;
        height: 30px;
        transform: rotate(1deg) scale(.3);
        border-radius: 100%;
    }

    100% {
        transform: rotate(-720deg) scale(1.5);
        width: 100%;
    }
}

body,
html {
    height: 100%;
    margin: 0 auto;
    min-height: 100%;
    overflow: hidden;
    position: relative;
}

body {
    display: flex;
    align-items: center;
    justify-content: center;
}

.marquee {
    display: block;
    left: 0;
    position: absolute;
    top: 0;
}

@-webkit-keyframes rotationfirst {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(45deg);
    }
}

* {
    box-sizing: border-box;
}

.weird-video-background {
    background-image: url(./static/weird.gif);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999999;
}

.video-background {
    display: none;
    background-image: url(./static/seals1.gif);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.video-foreground,
.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

#vidtop-content {
    top: 0;
    color: #fff;
    z-index: 1;
}

.vid-info {
    position: absolute;
    top: 0;
    right: 0;
    width: 33%;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 1rem;
    font-family: Avenir, Helvetica, sans-serif;
}

.vid-info h1 {
    font-size: 2rem;
    font-weight: 700;
    margin-top: 0;
    line-height: 1.2;
}

.vid-info a {
    display: block;
    color: #fff;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.5);
    transition: .6s background;
    border-bottom: none;
    margin: 1rem auto;
    text-align: center;
}

@media (min-aspect-ratio: 16/9) {
    .video-foreground {
        height: 300%;
        top: -100%;
    }
}

@media (max-aspect-ratio: 16/9) {
    .video-foreground {
        width: 300%;
        left: -100%;
    }
}

@media all and (max-width: 600px) {
    .vid-info {
        margin-top: 100vh;
        width: 100%;
        padding: .5rem;
    }

    .vid-info h1 {
        margin-bottom: .2rem;
    }
}

@media all and (max-width: 500px) {
    .vid-info .acronym {
        display: none;
    }
}

img.thebest {
    margin: 20px;
    height: 100px;
    -webkit-animation: rotationfirst .1s infinite linear alternate;
}

img.thesecondbest {
    margin: 20px;
    height: 100px;
    -webkit-animation: rotationsecond .1s infinite linear alternate;
}

img.thethirdbest {
    margin: 20px;
    height: 100px;
    -webkit-animation: rotationthird .5s infinite linear alternate;
}


@-webkit-keyframes rotationfirst {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(45deg);
    }
}

@-webkit-keyframes rotationsecond {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-45deg);
    }
}

@-webkit-keyframes rotationthird {
    0% {
        -webkit-transform: rotate(-270deg);
    }

    100% {
        -webkit-transform: rotate(270deg);
    }
}