html, body {
  cursor: url("boombox-cursor.96d7ab20.png"), auto;
  color: #fff;
  background-image: url("seals1.d7611227.gif");
}

#body1 {
  height: 100%;
  width: 100%;
  animation-timing: cubic-bezier(1, 1, .1, 0);
  animation-name: stretch;
  animation-duration: 10s;
  animation-delay: 0;
  background-image: url("static/patrick.gif");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-play-state: running;
  animation-fill-mode: none;
}

@keyframes stretch {
  0% {
    width: 10px;
    height: 30px;
    border-radius: 100%;
    transform: rotate(1deg)scale(.3);
  }

  100% {
    width: 100%;
    transform: rotate(-720deg)scale(1.5);
  }
}

body, html {
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

body {
  justify-content: center;
  align-items: center;
  display: flex;
}

.marquee {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@-webkit-keyframes rotationfirst {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(45deg);
  }
}

* {
  box-sizing: border-box;
}

.weird-video-background {
  z-index: 9999999;
  background-image: url("weird.a45c4e74.gif");
  position: fixed;
  inset: 0;
}

.video-background {
  z-index: 0;
  background-image: url("seals1.d7611227.gif");
  display: none;
  position: fixed;
  inset: 0;
}

.video-foreground, .video-background iframe {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

#vidtop-content {
  color: #fff;
  z-index: 1;
  top: 0;
}

.vid-info {
  width: 33%;
  color: #fff;
  background: #0000004d;
  padding: 1rem;
  font-family: Avenir, Helvetica, sans-serif;
  position: absolute;
  top: 0;
  right: 0;
}

.vid-info h1 {
  margin-top: 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

.vid-info a {
  color: #fff;
  text-align: center;
  background: #00000080;
  border-bottom: none;
  margin: 1rem auto;
  text-decoration: none;
  transition: background .6s;
  display: block;
}

@media (min-aspect-ratio: 16 / 9) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16 / 9) {
  .video-foreground {
    width: 300%;
    left: -100%;
  }
}

@media (max-width: 600px) {
  .vid-info {
    width: 100%;
    margin-top: 100vh;
    padding: .5rem;
  }

  .vid-info h1 {
    margin-bottom: .2rem;
  }
}

@media (max-width: 500px) {
  .vid-info .acronym {
    display: none;
  }
}

img.thebest {
  height: 100px;
  margin: 20px;
  -webkit-animation: rotationfirst .1s linear infinite alternate;
}

img.thesecondbest {
  height: 100px;
  margin: 20px;
  -webkit-animation: rotationsecond .1s linear infinite alternate;
}

img.thethirdbest {
  height: 100px;
  margin: 20px;
  -webkit-animation: rotationthird .5s linear infinite alternate;
}

@-webkit-keyframes rotationsecond {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-45deg);
  }
}

@-webkit-keyframes rotationthird {
  0% {
    -webkit-transform: rotate(-270deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
  }
}

/*# sourceMappingURL=index.ac8d3ef1.css.map */
